<template>
  <div class="withdraw-download">
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col md="4">
            <label for="inline-form-custom-select-pref">Type:</label>
            <v-select
              v-model="business"
              :options="businessOptions"
              class="invoice-filter-select mb-1 no-clear-select"
              placeholder="All"
            />
          </b-col>
          <b-col md="4">
            <b-button
              variant="primary"
              class="mb-1 mr-2 b-col-top"
              @click="search"
            >Search</b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refDownloadTable"
        :items="withdrawDownloadList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative download-table"
      >
        <!-- Column: filename -->
        <template #cell(filename)="data">
          {{ data.value }}
        </template>
        <!-- Column: createdAt -->
        <template #cell(createdAt)="data">
          <span class="text-nowrap"> {{ updateDate(data.value, timeZone) }}</span>
        </template>
        <!-- Column: updatedAt -->
        <template #cell(updatedAt)="data">
          <span class="text-nowrap"> {{ updateDate(data.value, timeZone) }}</span>
        </template>
        <!-- Column: statusName -->
        <template #cell(statusName)="data">
          <b-badge :variant="status[data.value]">
            {{ data.value }}
          </b-badge>
        </template>
        <!-- Column: url -->
        <template #cell(url)="data">
          <template
            v-if="data.item.statusName === 'Generated' && !!data.item.url"
          >
            <b-button
              variant="outline-success"
              @click="downloadFile(data.item)"
            >
              <!-- <b-link :href="data.item.url"> -->
              Download
              <!-- </b-link> -->
            </b-button>
          </template>
          <template
            v-else-if="data.item.statusName === 'Generated' && !data.item.url"
          >
            <b-button
              variant="outline-warning"
              disabled
            >
              <span class="align-middle">no url</span>
            </b-button>
          </template>
          <!-- Generating Failed -->
          <template v-else>
            <b-button
              variant="outline-info"
              disabled
            >
              <span class="align-middle">{{ data.item.statusName }}</span>
            </b-button>
          </template>
        </template>
        <template #cell(param)="data">
          <b-button
            variant="outline-success"
            @click="showDetail(data.item.param, data.item)"
          >
            Detail
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePagination"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- detail -->
    <b-modal
      ref="detail-modal"
      title="Detail"
      ok-only
      ok-title="Confirm"
      size="lg"
      class="detail-modal"
    >
      <b-card-text v-if="params.businessName == 'purchase'">
        <b-row class="m-1">
          <b-col md="4">
            <span>Purchase Statu:
              <b>{{ purchaseStatusArray[params.transaction_status] }}</b></span>
          </b-col>
        </b-row>
        <b-row
          v-if="params.created_time_scope.start_date"
          class="m-1"
        >
          <b-col md="8">
            <span>Create Time:
              <b>{{ updateDate(params.created_time_scope.start_date) }} to
                {{ updateDate(params.created_time_scope.end_date) }}</b></span>
          </b-col>
        </b-row>
        <b-row
          v-if="params.updated_time_scope.start_date"
          class="m-1"
        >
          <b-col md="8">
            <span>Update Time:
              <b>{{ updateDate(params.updated_time_scope.start_date) }} to
                {{ updateDate(params.updated_time_scope.end_date) }}</b></span>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col md="4">
            <span>Currency: <b>{{ params.currency }}</b></span>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col
            v-if="params.user_search.model === 3"
            md="8"
          >
            <span>User ID: <b>{{ params.user_search.key }}</b></span>
          </b-col>
          <b-col
            v-if="params.user_search.model === 4"
            md="8"
          >
            <span>Phone: <b>{{ params.user_search.key }}</b></span>
          </b-col>
          <b-col
            v-if="params.user_search.model === 5"
            md="8"
          >
            <span>Document ID: <b>{{ params.user_search.key }}</b></span>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col
            v-if="params.order_search.model === 1"
            md="8"
          >
            <span>Transaction ID: <b>{{ params.order_search.key }}</b></span>
          </b-col>
          <b-col
            v-if="params.order_search.model === 2"
            md="8"
          >
            <span>Third-party ID: <b>{{ params.order_search.key }}</b></span>
          </b-col>
        </b-row>
      </b-card-text>

      <b-card-text v-if="params.businessName == 'deposit'">
        <b-row class="m-1">
          <b-col md="12">
            <span>Search Key: <b>{{ params.searchKey }}</b></span>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col md="4">
            <span>Mode: <b>{{ modelData[params.model] }}</b></span>
          </b-col>
          <b-col md="4">
            <span>Deposit status:
              <b>{{ walletStatusData[params.depositStatus] }}</b></span>
          </b-col>
        </b-row>
        <b-row
          v-if="params.startDate && params.endDate"
          class="m-1"
        >
          <b-col md="8">
            <span>Time range:
              <b>{{ params.startDate }} to {{ params.endDate }}</b></span>
          </b-col>
        </b-row>
      </b-card-text>

      <b-card-text v-if="params.businessName == 'withdraw'">
        <b-row class="m-1">
          <b-col md="12">
            <span>Search Key: <b>{{ params.searchKey }}</b></span>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col md="4">
            <span>Mode: <b>{{ modelData[params.model] }}</b></span>
          </b-col>
          <b-col md="4">
            <span>Wallet status:
              <b>{{ walletStatusData[params.withdrawStatus] }}</b></span>
          </b-col>
          <b-col md="4">
            <span>Channel: <b>{{ channelData[params.withdrawChannel] }}</b></span>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col
            v-if="params.startDate && params.endDate"
            md="8"
          ><span>Time range:
            <b>{{ params.startDate }} to {{ params.endDate }}</b></span></b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BTable,
  BCard,
  BLink,
  BButton,
  BCardText,
  BBadge,
  BRow,
  BCol,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { createNamespacedHelpers } from 'vuex'
import { updateDate } from '@/libs/utils'

const { mapState: mapStateWallet } = createNamespacedHelpers('wallet')
export default {
  name: 'WithdrawDownload',
  components: {
    BTable,
    BCard,
    BButton,
    BCardText,
    BBadge,
    vSelect,
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {
      withdrawDownloadList: [],
      tableColumns: [
        { key: 'id', label: 'ID' },
        { key: 'filename', label: 'FILENAME' },
        { key: 'createdAt', label: 'CREATE TIME' },
        { key: 'updatedAt', label: 'UPDATE TIME' },
        { key: 'businessName', label: 'Type' },
        // { key: 'partnerId', label: 'Channel ID', formatter: val => `$${val}` },
        { key: 'statusName', label: 'STATUS NAME' },
        { key: 'url', label: 'ACTIONS' },
        { key: 'param', label: 'DETAILS' },
      ],
      status: {
        Generated: 'light-success',
        Generating: 'light-primary',
        Failed: 'light-danger',
      },
      params: {},
      business: {
        label: 'All',
        value: 0,
      },
      businessOptions: [
        { label: 'All', value: 0 },
        { label: 'withdraw', value: 1 },
        { label: 'deposit', value: 2 },
        { label: 'purchase', value: 3 },
        { label: 'prize distribution', value: 4 },
      ],
      modelData: {
        1: 'TransactionId',
        2: 'ChannelId',
        3: 'UserId',
        4: 'Phone',
        5: 'DocumentId',
      },
      walletStatusData: {
        '-1': 'All',
        0: 'Pending',
        1: 'Success',
        2: 'Rejected',
        3: 'Processing',
      },
      channelData: {
        '-1': 'All',
        1: 'Bank Transfer',
        2: 'Picpay',
        3: 'Pix',
        4: 'Mercadopago Wallet',
      },
      currentPage: 1,
      total: 0,
      perPage: 10,
      purchaseStatusArray: [],
    }
  },
  computed: {
    ...mapStateWallet(['timeZone']),
    dataMeta() {
      const localItemsCount = this.$refs.refDownloadTable
        ? this.$refs.refDownloadTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.total,
      }
    },
  },
  watch: {
    timeZone() {
      // this.withdrawDownload()
    },
  },
  mounted() {
    this.withdrawDownload()
    this.fetchWalletStatusOption()
  },
  methods: {
    search() {
      this.currentPage = 1
      this.withdrawDownload()
    },
    withdrawDownload() {
      this.$showLoading()
      this.$http
        .post('/api/v1/files/list', {
          business: this.business.value,
          timezone: this.timeZone,
          pageNo: this.currentPage,
          pageSize: this.perPage,
        })
        .then(response => {
          this.$hideLoading()
          if (response.data.code == 200 && response.data.data) {
            this.updateDownloadList(response.data.data.list)
            this.total = response.data.data.page.tatalCount
          } else if (response.data.code == 200 && !response.data.data) {
            this.withdrawDownloadList = []
          } else {
            this.showToast('warning', response.data.message)
          }
        })
        .catch(error => {
          this.$hideLoading()
          this.showToast('warning', error.message)
        })
    },
    updateDownloadList(data) {
      if (!data) {
        this.withdrawDownloadList = []
        return
      }
      // data.forEach(bean => {
      //   bean.createdAt = updateDate(bean.createdAt, this.timeZone)
      //   bean.updatedAt = updateDate(bean.updatedAt, this.timeZone)
      // })
      this.withdrawDownloadList = data
    },
    showDetail(value, data) {
      if (!value) return
      this.params = JSON.parse(value)
      this.params.businessName = data.businessName

      this.params.startDate = this.params.startDate
        && updateDate(this.params.startDate, this.timeZone)
      this.params.endDate = this.params.endDate && updateDate(this.params.endDate, this.timeZone)

      this.$refs['detail-modal'].show()
    },
    downloadFile(row) {
      window.open(
        `${process.env.VUE_APP_REQUEST_URL}/api/v1/files/download?id=${row.id}&uuid=${row.uuid}&timezone=${this.timeZone}`,
      )
    },
    changePagination(page) {
      this.currentPage = page
      this.withdrawDownload()
    },
    showToast(variant = null, message = '') {
      this.$bvToast.toast(`${message}`, {
        title: `${variant || 'Error'}`,
        variant,
        solid: true,
      })
    },
    // 获取状态
    fetchWalletStatusOption() {
      // store      .dispatch('purchases/fetchWalletStatus')
      this.$showLoading()
      this.$http.get('/api/v1/admin/getWalletStatus').then(response => {
        this.$hideLoading()
        if (response.data.code === 200) {
          const { data } = response.data
          this.purchaseStatusArray = data.reduce((initValue, value) => Object.assign(initValue, value))
        }
      })
    },
  },
  setup() {
    return { updateDate }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.withdraw-download {
  ::v-deep .download-table {
    .table tr td:nth-of-type(2) {
      word-break: break-all;
      word-wrap: break-all;
      width: 20rem;
      display: inline-block;
    }
    .table tr td:nth-of-type(3) {
      min-width: 10rem;
    }
    .table tr td:nth-of-type(4) {
      min-width: 10rem;
    }
    .table tr td:nth-of-type(6) {
      min-width: 7.5rem;
    }
  }
  .b-col-top {
    margin-top: 1.67rem;
  }
}
.detail-modal {
  max-width: 700px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
